<template>
  <base-dialog v-model="dialog" title="Permissionamento" max-width="700" persistent>
    <v-container>
      <v-row>
        <v-col>
          <mf-select v-model="role" item-value="value" item-text="text" :disabled="!selected.length" :items="roles" label="Role" outlined></mf-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-alert v-if="!selected.length" dense type="error" class="text-center">
            Certifique-se de selecionar os usuários antes de executar esta ação.
          </v-alert>
          <div v-else>
            <h4 class="text-center">Usuários:</h4>
            <v-row justify="center">
              <v-col cols="auto">
                <v-chip
                  v-for="item in selected"
                  :key="item._id"
                  class="mx-auto mt-2 justify-center"
                  style="justify-content: space-between"
                  close
                  @click:close="removeSelected(item._id)"
                >
                  {{ item.login }}
                </v-chip>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col v-if="role && selected.length">
          <h4 class="text-center">Acessos:</h4>
          <v-row justify="center">
            <v-col cols="auto">
              <v-chip v-for="access in accessType" :key="access.index" class="mx-auto mt-2" style="justify-content: space-between">{{ access }}</v-chip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <mf-button color="error" class="mt-3" outlined label="Cancelar" @click="close"></mf-button>
        </v-col>
        <v-col cols="6">
          <v-row justify="end">
            <v-col cols="auto">
              <mf-button :disabled="!selected.length || !role" label="Atribuir" color="primary" @click="trigger"></mf-button>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-dialog>
</template>

<script>
import { MUTATION_DEFINE_ROLE } from '@/modules/session/graphql'
import BaseDialog from '../../../components/atomic-components/atoms/BaseDialog.vue'

export default {
  components: {
    BaseDialog
  },
  props: {
    dialog: Boolean,
    selected: Array
  },
  data: () => ({
    roles: [
      { value: 'DEV', text: 'Dev' },
      { value: 'MANAGER', text: 'Manager' },
      { value: 'SUPPORT', text: 'Support' },
      { value: 'DEPLOYMENT', text: 'Deployment' },
      { value: 'ANALYST', text: 'Analyst' }
    ],
    role: ''
  }),
  computed: {
    accessType() {
      if (this.role === 'DEV') return ['*']
      if (this.role === 'MANAGER') return ['*']
      if (this.role === 'SUPPORT' || this.role === 'DEPLOYMENT') return ['Tudo, exceto "Gestão de Usuários"']
      if (this.role === 'ANALYST') return ['Visualização']
      return []
    }
  },
  methods: {
    async trigger() {
      const variables = {
        user_id: this.selected.map(user => user._id),
        role: this.role
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_DEFINE_ROLE,
          variables
        })
        this.$snackbar({ message: 'Role atribuída com sucesso' })
        this.close()
      } catch (err) {
        this.$snackbar({ message: 'Falha ao definir roles' })
      }
    },
    close() {
      this.role = ''
      this.$emit('close')
    },
    removeSelected(id) {
      const selected = this.selected.filter(item => item._id === id)
      this.selected.splice(this.selected.indexOf(selected[0]), 1)
      this.$emit('update:selected', this.selected)
    }
  }
}
</script>

<style></style>
